export const DELIVERY_METODS = {
	COURIER:"COURIER",
  PICKUP:"PICKUP",
	ONSPOT:"ONSPOT"
}
export const ORG_STATUS = {
	WORK:"WORK",
	NOWORK:"NOWORK",
	ONWORK:"ONWORK",
	OPEN:"OPEN",
	NODELIVERY:"NODELIVERY",
	SEZONNOTWORK:"SEZONNOTWORK"
}

export const PAYMENT_METODS = {
	CASH:"CASH",
	BYCARD:"BYCARD",
	CARD:"CARD"
}

export const DILIVERY_TIME_STATUS = {
	NODELIVERY:"NODELIVERY",
	ONLIPICKUP:"ONLIPICKUP"
}